<template>
  <NuxtLayout name="dashboard" :containerless="true">
    <!-- TOP BAR FILTERS -->
    <div
      :class="{ 'shadow-top': isScrolled }"
      class="p-4 sticky top-0 left-0 flex flex-row space-x-3 border-b border-gray-200 bg-gray-50 justify-between z-50"
    >
      <UButton
        size="xl"
        :ui="{
          rounded: 'rounded-full',
        }"
        :variant="filter ? 'outline' : 'solid'"
        @click="() => toggleTags(filter)"
        >All templates
      </UButton>

      <UButton
        size="xl"
        :ui="{
          rounded: 'rounded-full',
        }"
        :variant="filter?.name === 'For you' ? 'solid' : 'outline'"
        @click="() => toggleTags({ name: 'For you', key: 'for-you' })"
        >For you
      </UButton>
      <div v-for="(categories, section) in categorySections" :key="section">
        <USelectMenu
          v-model="filter"
          :options="categories"
          :popper="{ placement: 'bottom' }"
        >
          <UButton
            size="xl"
            truncate
            class="flex-1"
            :ui="{
              rounded: 'rounded-full',
              base: isActiveSection(section) ? 'shrink-0' : 'shrink',
            }"
            :variant="isActiveSection(section) ? 'solid' : 'outline'"
          >
            <UIcon
              v-if="isActiveSection(section)"
              :name="filter?.icon"
              class="text-2xl"
            />
            {{
              isActiveSection(section)
                ? filter?.name
                : section.replace('By ', '')
            }}
            <UIcon
              :name="
                isActiveSection(section)
                  ? 'i-ph-caret-down-bold'
                  : 'i-ph-caret-down'
              "
            />
          </UButton>

          <template #option="{ option: category }">
            <span class="flex gap-1" @click="toggleTags(category)">
              <UIcon :name="category.icon" />
              <span class="truncate">{{ category.name }}</span>
            </span>
          </template>
        </USelectMenu>
      </div>
      <div class="column flex is-left is-relative">
        <UInput
          v-model="liveQuery"
          :loading="isSearching"
          icon="i-ph-magnifying-glass-bold"
          color="white"
          variant="outline"
          class="flex-1"
          size="lg"
          placeholder="Search templates..."
          :ui="{ icon: { trailing: { pointer: '' } } }"
          @input="debouncedSearch($event.target.value)"
        >
          <template #trailing>
            <UButton
              v-show="liveQuery !== ''"
              color="gray"
              variant="link"
              icon="i-ph-x"
              :padded="false"
              @click="clear"
            />
          </template>
        </UInput>
      </div>
    </div>
    <!-- TODO: Remove banner -->

    <UAlert
      icon="i-ph-warning-circle"
      color="primary"
      size="xl"
      variant="soft"
      title="Check back later!"
      description="We just released a new update. We're adding templates as quickly as we
      can."
      class="w-full"
      :ui="{
        title: 'font-bold',
      }"
    />
    <!-- HEADER -->
    <UContainer :ui="{ base: 'h-fit' }">
      <div class="flex flex-row mb-l" style="justify-content: space-between">
        <div class="flex flex-col" style="align-items: flex-start">
          <div class="flex flex-row mb-m" style="justify-content: flex-start">
            <UIcon
              v-if="filter && filter?.name !== 'For you'"
              :name="filter?.icon"
              class="text-4xl mr-s"
            />
            <p class="main-title">
              {{ filter?.name ?? 'All templates' }}
            </p>
          </div>
          <p v-if="filter?.name === 'For you'" class="body dimmed">
            Explore these templates we picked just for you
          </p>
          <p v-else class="body dimmed">
            Explore our ready-to-go {{ filter?.name ?? 'spell' }} templates
          </p>
        </div>
      </div>
      <!-- SPELLS -->
      <div v-if="!isSearching" class="contents">
        <!-- ALL TEMPLATE SPELLS -->
        <div
          v-if="templateResults.length > 1"
          key="showing-all"
          class="contents"
        >
          <!-- WITH SEARCH QUERY -->
          <div v-if="searchQuery !== ''" key="showing-all-search" class="mb-m">
            <div key="all-search-grid" class="spell-grid">
              <div
                v-for="section in templateResults"
                :key="section.category"
                class="contents"
              >
                <div v-for="template in section.templates" :key="template.id">
                  <AppSpellCard
                    :spell="template.spell"
                    :preview="template.preview"
                    templated
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- NO SEARCH QUERY -->
          <div
            v-for="row in templateResults"
            v-else
            :key="`row-${row.category}`"
            class="mb-l z-1 w-full max-w-full"
          >
            <p class="flex is-left title mb-s pl-s">
              {{ categoryMap[row.category]?.name }}
            </p>
            <AppSpellCarousel :spells="row.templates" templated />
          </div>
        </div>
        <!-- FILTERED TEMPLATE SPELLS -->
        <div
          v-else-if="templateResults.length === 1"
          :key="`showing-${templateResults[0].category}`"
        >
          <div :key="`grid-${templateResults[0].category}`" class="spell-grid">
            <div
              v-for="template in templateResults[0].templates"
              :key="`${template.id}-in-${templateResults[0].category}`"
            >
              <AppSpellCard
                :spell="template.spell"
                :preview="template.preview"
                templated
              />
            </div>
          </div>
        </div>
        <!-- NO RESULTS -->
        <div v-else key="none">
          <div class="flex flex-col justify-center items-center h-60">
            <UIcon name="i-ph-smiley-melting" class="text-gray-400 text-3xl" />

            <p class="h4 mt-ls mb-xs">No spells found</p>

            <p class="body dimmed flex is-wrap">
              Sorry, we couldn't find any relevant spells.

              <UButton
                label="Create"
                variant="link"
                icon="i-ph-note-pencil"
                color="primary"
                size="xl"
                :padded="false"
                class="p-1"
                :ui="{
                  gap: {
                    xl: 'gap-x-1',
                  },
                }"
                @click="() => createSpell()"
              />

              a new spell or
              <!-- TODO enable Unthread -->
              <UButton
                label="make a request"
                variant="link"
                icon="i-ph-chat-circle"
                color="primary"
                disabled
                size="xl"
                :padded="false"
                class="p-1"
                :ui="{
                  gap: {
                    xl: 'gap-x-1',
                  },
                }"
              />
              to our team.
            </p>
          </div>
        </div>
      </div>
      <!-- IS LOADING -->

      <div
        v-else
        key="loading"
        class="flex justify-center items-center w-full h-60"
      >
        <AppLoadingSpinner />
      </div>
    </UContainer>
  </NuxtLayout>
</template>
<script setup lang="ts">
import AppLoadingSpinner from '~/components/app/AppLoadingSpinner.vue';
import AppSpellCarousel from '~/components/app/AppSpellCarousel.vue';
import { useSpellsStore } from '~/stores/spells';
import { categories as categoryMap, categorySections } from '~/util/categories';

useSeoMeta({
  title: 'Templates Page - AI Workflows & Automations',
  ogTitle: 'Templates Page - AI Workflows & Automations',
  description:
    'Browse through our pre-made AI workflow automations, all without code.',
  ogDescription:
    'Browse through our pre-made AI workflow automations, all without code.',
});

const spellStore = useSpellsStore();

const searchTags = ref([]);
const searchQuery = ref('');

await useLazyAsyncData(
  'templates',
  () => spellStore.searchTemplates(searchQuery.value, searchTags.value),
  { watch: [searchQuery, searchTags], dedupe: 'defer' },
);

const {
  relevantTags,
  isSearching,
  templateResults: searchResults,
} = storeToRefs(spellStore);
const { createSpell } = spellStore;

const filter = ref(null);
const isScrolled = ref(false);
const liveQuery = ref('');

const isActiveSection = computed(() => {
  return (section) => filter.value?.section === section;
});

const templateResults = computed(() => {
  const categoryTemplates = {} as Record<string, any>;

  searchResults.value.forEach((template) => {
    if (template.spell.tags?.length > 0) {
      if (filter.value) {
        const category = filter.value.name;
        if (!categoryTemplates[category]) {
          categoryTemplates[category] = [];
        }
        categoryTemplates[category].push(template);
      } else if (searchQuery.value === '') {
        template.spell.tags.forEach((tag) => {
          if (!categoryTemplates[tag]) {
            categoryTemplates[tag] = [];
          }
          categoryTemplates[tag].push(template);
        });
      } else {
        const firstTag = template.spell.tags[0];
        if (!categoryTemplates[firstTag]) {
          categoryTemplates[firstTag] = [];
        }
        categoryTemplates[firstTag].push(template);
      }
    }
  });

  return Object.entries(categoryTemplates).map(([category, templates]) => ({
    category,
    templates,
  }));
});

onMounted(() => {
  const handleScroll = () => {
    isScrolled.value = window.scrollY > 0;
  };

  window.addEventListener('scroll', handleScroll);

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
});

const clear = () => {
  liveQuery.value = '';
  searchQuery.value = '';
};

const toggleTags = (option) => {
  if (filter.value === option) {
    searchTags.value = [];
    filter.value = null;
  } else if (option.key === 'for-you') {
    searchTags.value = relevantTags.value;
    filter.value = option;
  } else {
    searchTags.value = [option.key];
    filter.value = option;
  }
};

const debouncedSearch = useDebounceFn(
  (query: string) => {
    searchQuery.value = query;
  },
  300,
  { maxWait: 500 },
);
</script>

<style lang="scss">
.list-transition-enter-active,
.list-transition-leave-active {
  transition: opacity 0.1s;
}

.list-transition-enter-from,
.list-transition-leave-to {
  opacity: 0;
}

.list-transition-enter-to,
.list-transition-leave-from {
  opacity: 1;
}

/* Initial state without shadow, but ready for transition */

.top-middle-bar {
  z-index: 5;
  transition: box-shadow 0.4s ease-in-out;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);

  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }

  @media only screen and (min-width: 600px) and (max-width: 960px) {
    margin-left: 90px;
    width: calc(100vw - 90px);
  }

  @media only screen and (min-width: 960px) and (max-width: 1264px) {
    margin-left: 305px;
    width: calc(100vw - 305px);
  }

  @media only screen and (min-width: 1264px) and (max-width: 1904px) {
    margin-left: 305px;
    width: calc(100vw - 305px);
  }

  @media only screen and (min-width: 1904px) {
    margin-left: 305px;
  }
}

/* Shadow state */
.shadow-top {
  box-shadow: 0 4px 5px -4px rgba(0, 0, 0, 0.1);
}
</style>
